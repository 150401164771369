.theo-primary-color,
.vjs-selected {
  color: #61dafb !important;
}

.theo-primary-background {
  color: #000000 !important;
  background-color: #61dafb !important;
}

.theo-secondary-color {
  color: #ffffff !important;
}

.theo-secondary-background {
  color: #000000 !important;
  background-color: #ffffff !important;
}

.theo-tertiary-color {
  color: #000000 !important;
}

.theo-tertiary-background {
  color: #ffffff !important;
  background-color: #000000 !important;
}
